@import "styles/core.scss";
.reviewUser {
  @include flex-row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.ratingTags {
  margin-top: var(--space-300);
  display: flex;
  gap: var(--space-100);
  flex-wrap: wrap;
}

.summaryExclusion {
  margin-bottom: var(--space-100);
}

.badgeContainer {
  @include flex-row;
  @include text-200-bold;
  gap: var(--space-100);
  color: var(--color-text-info);
  align-items: center;
}

.reviewBody {
  margin-bottom: 0;
  margin-top: var(--space-300);
}

.reviewBodyFirst {
  background-color: var(--color-background-secondary);
  padding: var(--space-200);
  border-radius: var(--radius-md);

  .reviewText {
    margin-bottom: 0;
  }
}

.reviewText {
  @include paragraph-200;
  margin-top: 0;
  margin-bottom: 0;
}

.attributes {
  margin-top: var(--space-200);
}

.obstacles {
  @include text-200;
  display: block;
  margin: var(--space-100) 0 var(--space-200);
}

.reviewActions {
  @include flex-row;
  gap: var(--space-100);

  .divider {
    border-left: 1px solid var(--color-border-separator);
  }
}

.replyEditContainer {
  @include flex-column;
  margin-top: var(--space-200);
  margin-bottom: 0;
  padding: var(--space-200);
  border: var(--borders-weight-sm) solid var(--color-border-separator);
  border-radius: var(--radius-md);

  .replyActions {
    @include flex-row;
    gap: var(--space-100);
    justify-content: flex-end;
    margin-top: var(--space-450);
  }
}

.newReplyEditContainer {
  background-color: var(--color-background-secondary);
  margin-top: var(--space-200);
  padding: var(--space-200);
  margin-bottom: 0;
}

.replyContainer {
  @include flex-row;
  gap: var(--space-200);
  min-height: 64px;
  margin: var(--space-450) 0;

  .borderBlock {
    background-color: var(--color-border-separator);
    width: var(--space-50);
    min-width: 4px;
    border-radius: var(--radius-sm);
  }

  .replyTextContainer {
    @include text-200;
    @include flex-column;
    flex: 1 1 auto;
    gap: 10px;
  }
}

.translationButton {
  margin-top: var(--space-200);
}

.attributeTitle {
  margin-right: var(--space-50);
}

.popover {
  width: 171px;
  z-index: 2;
}

.menuList {
  @include flex-column;
  overflow-y: auto;
  padding: var(--space-100);
}

.menuItem {
  display: flex;
  align-items: center;
  @include text-100;
  padding: var(--space-100);
  cursor: pointer;
  height: 40px;
  text-align: left;
  border: 0;
  background: var(--color-neutral-white);
  justify-content: flex-start;

  &:hover {
    border-radius: var(--radius-md);
    background-color: var(--color-background-secondary);
  }
}

.connectItem {
  display: flex;
  @include removeDefaultButtonStyles;
  @include text-100;
  padding: 0 !important; // overwrite default button styles
  justify-content: flex-start;
  text-align: left;
  width: 100%;

  &:hover {
    border-radius: var(--radius-md) !important; // overwrite default button styles
    background-color: var(--color-background-secondary) !important; // overwrite default button styles
    box-shadow: none;
  }
}

.thirdPartyReview,
.adminOptions {
  @include flex-column;
}

.adminItem {
  display: flex;
  align-items: center;
  @include text-100;
  color: var(--color-text-secondary);
  padding: var(--space-100);
  display: flex;
  text-align: left;
  width: 100%;
  border: 0;
  background: var(--color-neutral-white);
  justify-content: flex-start;
}

$photoBorderSize: var(--borders-weight-lg);

.photosContainer {
  position: relative;
}

.photoCarousel {
  @include flex-row;
  flex-wrap: nowrap;
  gap: calc(var(--space-100) - $photoBorderSize * 2);
  margin-bottom: calc(var(--space-200) - $photoBorderSize);
  overflow-x: auto;
}

$photoHeight: 112px;
$photoWidth: 112px;

.photosOverflowRight:after {
  @include page-width-lg-up {
    content: '';
    width: 30px;
    height: calc($photoHeight + $photoBorderSize * 2);
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0) 10%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    right: 0;
    top: 0;
    transition: background-image 0.7s ease-out;
  }
}

.photosOverflowLeft:before {
  @include page-width-lg-up {
    content: '';
    width: 30px;
    height: calc($photoHeight + $photoBorderSize * 2);
    background-image: linear-gradient(to left, rgba(255, 0, 0, 0) 10%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    left: 0;
    top: 0;
    transition: background-image 0.7s ease-out;
  }
}

.photoInteractive {
  @include removeDefaultButtonStyles;
  width: calc($photoWidth + $photoBorderSize * 2);
  height: calc($photoHeight + $photoBorderSize * 2);
  border-radius: calc(var(--radius-sm) + $photoBorderSize);
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:focus-within {
    background-color: var(--color-border-focus-default);
  }
}

.newPhotoCarousel {
  margin-bottom: 0;
  margin-top: var(--space-300);
}

.errorBackground,
.photo {
  width: $photoWidth;
  height: $photoHeight;
  border-radius: var(--radius-sm);
  background-color: var(--color-background-tinted-inverse);
}

.errorBackground {
  background-color: var(--color-background-primary);
}

.errorIconContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-sm);
  background-color: var(--color-background-tinted-inverse);
}

.bottomLink {
  @include text-200-bold;
  margin-top: var(--space-300);
  @include flex-row;
  gap: var(--space-100);
  align-items: center;
}

.showAllPhotos {
  width: $photoWidth;
  height: $photoHeight;
  min-width: $photoWidth;
  background-color: var(--color-background-disabled);
  border-radius: var(--radius-sm);
  margin: $photoBorderSize;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include text-100;
  cursor: pointer;
}

.photosOverflowOnMobile {
  @include page-width-md-down {
    margin-left: calc(-1 * var(--breakpoint-md-margin));
    margin-right: calc(-1 * var(--breakpoint-md-margin));
    padding-left: var(--breakpoint-md-margin);
    padding-right: var(--breakpoint-md-margin);
  }

  @include page-width-sm-down {
    margin-left: calc(-1 * var(--space-300));
    margin-right: calc(-1 * var(--space-300));
    padding-left: var(--space-300);
    padding-right: var(--space-300);
  }

  @include page-width-xs {
    margin-left: calc(-1 * var(--space-200));
    margin-right: calc(-1 * var(--space-200));
    padding-left: var(--space-200);
    padding-right: var(--space-200);
  }
}

.divider {
  border: var(--borders-weight-sm) solid var(--color-border-separator);
  margin: var(--space-100) 0;
}

.heading {
  @include flex-column;
  padding-left: var(--space-150);
}

.newReviewTitle {
  @include flex-row;
}

.logoBackground {
  @include flex-row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: var(--color-brand-dark);
  border-radius: var(--radius-round);
}

.subtext {
  @include flex-row;
  align-items: center;
}
